import { ApiConfig, BfAppContext } from '@tw/tw-runtime';

import { AbstractRnApiService } from '@tw/tw-runtime-rn';
import { Platform } from 'react-native';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

// api service 是打包app生效的
export class ApiService extends AbstractRnApiService {
  // 正式版
  // http://124.222.140.208:8050
  // 测试版
  // http://81.70.208.61:8050
  getBaseUrl(): string {
    return Platform.OS === 'web' ? '/api' : 'http://124.222.140.208:8050';
  }

  onNetworkError(error: any) {
    console.error(error);
    super.onNetworkError(error);
  }

  protected onTokenInvalidateError() {
    console.log('未登录跳转回登录页');
    wx.miniProgram.redirectTo({
      url: '/pages/login/index?logout=true'
    });
  }
}
