import { BfDirectionService, BfProperties } from '@tw/tw-runtime';
import { Platform } from 'react-native';
import { TwBfFormConfig } from '@tw/tw-platform-rn';
if (Platform.OS === 'web') {
  BfProperties.set(BfDirectionService.PROP_API_PREFIX, '/_AMapService');
} else {
  BfProperties.set(BfDirectionService.PROP_API_PREFIX, 'https://restapi.amap.com');
}

TwBfFormConfig.set('showBottomBorderOnCreate', false);
