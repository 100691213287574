import React, { useEffect } from 'react';
import { theme } from '@/app/theme';
import { AppRouter } from './AppRouter';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import '@/app/core/setup/iconfont-setup';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';
import { TwLoadingBlock, TwProvider } from '@tw/tw-components-rn';
import Toast, { ToastProvider } from 'react-native-toast-notifications';

console.log('==version==', '2024年08月28日23:06:53');
SplashScreen.preventAutoHideAsync().then();

export default function App(props) {
  const { initLoadFinished } = useAppInitLoad();
  return (
    <TwProvider theme={theme}>
      <ToastProvider>
        {!initLoadFinished ? <TwLoadingBlock tip={'应用加载中'} /> : <AppRouter />}
        <Toast duration={1500} ref={(ref) => (global['toast'] = ref)} placement={'center'} />
      </ToastProvider>
    </TwProvider>
  );
}

function useAppInitLoad() {
  const appStore = useAppStore();
  const initLoadFinished = useStoreState(appStore, (state) => state.initLoadFinished);

  const [fontsLoaded] = useFonts({
    iconfont: require('@/app/assets/fonts/iconfont.ttf')
  });
  useEffect(() => {
    appStore.initialize().then();
  }, [appStore]);
  useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  return {
    initLoadFinished: initLoadFinished && fontsLoaded
  };
}
