import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';
const theme = TwTheme.values;

export const styles = StyleSheet.create({
  page: {},
  container: {
    backgroundColor: TwTheme.values.primaryColors.base,
    display: 'flex',
    paddingHorizontal: theme.spaces[4],
    paddingTop: theme.spaces[4],
    flex: 1
  },

  welcome: {
    ...theme.textSizes['3xl'],
    color: theme.colors.white,
    fontWeight: theme.textWeights.bold,
    marginBottom: theme.spaces[6],
    marginLeft: theme.spaces[6]
  },

  title: {
    ...theme.textSizes.md,
    fontWeight: theme.textWeights.bold,
    marginBottom: theme.spaces[1],
    color: theme.textColors.secondary
  },

  loginContainer: {
    borderColor: theme.primaryColors.base,
    borderWidth: theme.borderWidths[1],
    borderRadius: theme.radius.md,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: theme.spaces[6],
    paddingVertical: theme.spaces[8]
  },

  descTitle: {
    ...theme.textSizes.md,
    color: theme.textColors.third
  },
  submit: {
    marginTop: theme.spaces[8],
    color: theme.primaryColors.base,
    paddingHorizontal: 24,
    paddingVertical: 14
  },
  input: {
    height: 37
  },
  inputAction: {
    marginRight: theme.spaces[2],
    color: theme.primaryColors.base
  }
});
