import { GiftGiftHomePage } from '@/gift/pages/gift-home-page/GiftGiftHomePage';
import { GiftGiftMall } from '@/gift/pages/gift-mall/GiftGiftMall';
import { GiftGiftShopping } from '@/gift/pages/gift-shopping/GiftGiftShopping';
import { HomeMine } from '@/home/pages/mine/HomeMine';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { TwIcon } from '@tw/tw-components-rn';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';

const BottomTabs = createBottomTabNavigator();
export const HomeIndex = () => {
  return (
    <BottomTabs.Navigator
      screenOptions={{
        // TODO:修正TabBar颜色
        // tabBarActiveTintColor: TwTheme.values.primaryColors.active,
        tabBarInactiveTintColor: '#A4A4A4',
        tabBarActiveTintColor: '#8FBD69',
        headerShown: false
      }}
    >
      <BottomTabs.Screen
        name={ROUTE_NAMES.GiftGiftHomePage}
        component={GiftGiftHomePage}
        options={{
          title: '',
          tabBarLabel: '主页',
          tabBarIcon: getTabBarIcon('icon-home')
        }}
      />
      <BottomTabs.Screen
        name={ROUTE_NAMES.GiftGiftMall}
        component={GiftGiftMall}
        options={{
          tabBarLabel: '商城',
          title: '',
          tabBarIcon: getTabBarIcon('icon-gift')
        }}
      />
      <BottomTabs.Screen
        name={ROUTE_NAMES.GiftGiftShopping}
        component={GiftGiftShopping}
        options={{
          tabBarLabel: '清单',
          title: '',
          tabBarIcon: getTabBarIcon('icon-shopping-cart-add')
        }}
      />
      <BottomTabs.Screen
        name={ROUTE_NAMES.HomeMine}
        component={HomeMine}
        options={{
          tabBarLabel: '我的',
          title: '',
          tabBarIcon: getTabBarIcon('icon-me')
        }}
      />
    </BottomTabs.Navigator>
  );
};

const getTabBarIcon = (iconName: string) => {
  // eslint-disable-next-line react/display-name
  return ({ color, size }: { color: string; size: number }) => <TwIcon color={color} size={size} icon={iconName} />;
};
