import { Text, View } from 'react-native';
import React from 'react'
import { MaterialIcons } from '@expo/vector-icons';
import { TwBfFieldRcProps } from '@tw/tw-platform-react';
import { TwRow } from '@tw/tw-components-rn';
import { styles } from './styles';

export const ShopTitle = (props) => {
  const { name } = props;
  return (
    <TwRow space={1} style={styles.container}>
      <View style={styles.icon}>
        <MaterialIcons name="store-mall-directory" size={18} color="#8FBD69" />
      </View>
      <Text style={styles.title}>{name}</Text>
    </TwRow>
  );
};
