import { BfAbstractIntentNavigator } from '@tw/tw-runtime';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { SaleOrderDTO } from '@tw/clvip-api-gen/dist/types';

/**
 *
 */
class Navigator extends BfAbstractIntentNavigator<GiftCustomInfoNavigatorData> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.GiftGiftCustomInfo;
  }
}

export interface GiftCustomInfoNavigatorData {
  // 结束操作： 下单、无
  finishAction: GiftCustomInfoFinishAction;
  // 如果是下单，返回时带上该参数
  order?: SaleOrderDTO;
}

export enum GiftCustomInfoFinishAction {
  createOrder = 'createOrder'
}

export const GiftCustomInfoNavigator = new Navigator();
