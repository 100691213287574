import {
  BfAppContext,
  BfLoginFormState,
  BfLoginMethod,
  BfLoginStore,
  BfLoginStoreState,
  BfUserLoginParams,
  EnumValue,
  newEnumValue
} from '@tw/tw-runtime';
import { LoginDeviceTypeEnumValue, UserTypeEnumValue } from '@tw/clvip-api-gen';

export class LoginStore extends BfLoginStore {
  protected async doLogin(params: BfUserLoginParams): Promise<void> {
    // const loginReq: ResLoginRequestModel = {
    //   userType: newEnumValue(UserTypeEnumValue.company),
    //   loginMethod: newEnumValue(LoginMethodEnumValue.pwd),
    //   userName: params.userName,
    //   // captcha: params.captcha,
    //   pwd: params.pwd,
    //   deviceType: newEnumValue(LoginDeviceTypeEnumValue.android)
    // };
    params.userType = newEnumValue(UserTypeEnumValue.company);
    params.deviceType = newEnumValue(LoginDeviceTypeEnumValue.android);
    const appStore = BfAppContext.getInstance().getAppStore();
    await appStore.login(params);
  }

  protected getDefaultFormState(): BfLoginFormState {
    return {
      ...super.getDefaultFormState()
    };
  }

  protected getDefaultUserType(): EnumValue {
    return newEnumValue(UserTypeEnumValue.company);
  }

  protected getDefaultLoginMethod(): BfLoginMethod {
    return BfLoginMethod.pwd;
  }

  protected getInitialState(): BfLoginStoreState {
    return {
      ...super.getInitialState(),
      isPolicyAgreed: true
    };
  }
}
