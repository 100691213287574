import { HrDepartmentDTO, HrDepartmentRestApiService } from '@tw/clvip-api-gen';
import React, { FC, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { TwAutoComplete, TwListItem } from '@tw/tw-components-rn';

import { MaterialIcons } from '@expo/vector-icons';
import { TwFormItem } from '@tw/tw-components-rn';
import { TwTheme } from '@tw/tw-runtime-rn';
import { newLikeCondition } from '@tw/tw-runtime';
import { styles } from './styles';
import { useStQuery } from '@tw/tw-runtime-react';

interface DepartmentSelectProps {
  inputValue: string;
  department: HrDepartmentDTO;
  onChange: (value: string) => void;
  onSelect: (value: HrDepartmentDTO) => void;
  enableSearch: boolean;
}

export const DepartmentSelect2: FC<DepartmentSelectProps> = (props) => {
  const { inputValue, enableSearch, onChange, onSelect, department } = props;
  const [hasError, setHasError] = useState(false);

  const { isLoading, items, query } = useStQuery({
    refBO: {
      id: 'department',
      domain: 'hr'
    }
  });

  useEffect(() => {
    if (inputValue && !isLoading && inputValue !== department?.name) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [inputValue, department, isLoading]);

  const handleNameSearch = async (text: string) => {
    if (!enableSearch) {
      return;
    }
    query({
      conditions: [newLikeCondition(HrDepartmentRestApiService.NAME, text)],
      pageNo: 1,
      pageSize: 6,
      fields: [HrDepartmentRestApiService.ID, HrDepartmentRestApiService.NAME]
    });
  };

  // 选择已有客户
  const handleCustomSelect = (customer: HrDepartmentDTO) => {
    onSelect?.(customer);
  };

  // 选择已有客户
  const handleCustomChange = (text: string) => {
    console.log('handleCustomChange text:', text);
    onChange?.(text);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>选择所在部门</Text>

      <TwFormItem
        style={styles.item}
        labelStyle={styles.label}
        label={'所在部门'}
        helper={'如果没有找到部门，请联系【工会】'}
        pressable={true}
        layout="vertical"
        showArrow={false}
        bordered={false}
        showError={hasError}
        errors={['请从搜索结果中选择部门，如果没有找到部门，请联系【工会】']}
      >
        <TwAutoComplete
          isLoading={isLoading}
          options={items}
          value={inputValue}
          inputProps={{
            variant: 'outline',
            style: {
              paddingLeft: 5,
              ...TwTheme.values.textSizes.sm,
              alignSelf: 'flex-start',
              width: '100%'
            },
            placeholder: '搜索并选择部门',
            InputRightElement: (
              <View
                style={{
                  paddingRight: 5
                }}
              >
                <MaterialIcons name="keyboard-arrow-down" size={24} color="black" />
              </View>
            )
          }}
          onChange={handleCustomChange}
          onSearch={handleNameSearch}
          renderOption={(item: HrDepartmentDTO) => {
            return <TwListItem title={item.name} />;
          }}
          onSelect={handleCustomSelect}
        />
      </TwFormItem>
    </View>
  );
};
