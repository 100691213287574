import { BfAbstractIntentNavigator, BfIntent } from '@tw/tw-runtime';

import { ROUTE_NAMES } from '@/app/navigation/route-names';

/**
 *
 */
class Navigator extends BfAbstractIntentNavigator<any, AddressChoiceIntent> {
  get ROUTE_NAME() {
    return ROUTE_NAMES.SettingAddressChoice;
  }
}

/**
 * 地址跳转操作。
 * 调用 start 时传入
 */
export enum AddressChoiceAction {
  selectAddress = 'selectAddress'
}

export interface AddressChoiceIntent extends BfIntent {
  action?: AddressChoiceAction;
  selectedAddressId?: string;
}

export const AddressChoiceNavigator = new Navigator();
