import React, { useState } from 'react';
import { Text, View } from 'react-native';
import {
  TwAvatar,
  TwColumn,
  TwIcon,
  TwIconButton,
  TwImage,
  TwListItem,
  TwPage,
  TwPageHeader,
  TwRow,
  TwSection
} from '@tw/tw-components-rn';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';

import { BfAppContext } from '@tw/tw-runtime';
import { CommonStore } from '@/app/core/store/common/common-store';
import { MaterialIcons } from '@expo/vector-icons';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { TwButton } from '@/app/components/tw-button/TwButton';
import address from './assets/address.svg';
import certification from './assets/certification.svg';
import history from './assets/history.svg';
import money from './assets/money.svg';
import record from './assets/record.svg';
import { styles } from './styles';
import { useFocusEffect } from '@react-navigation/native';

export const HomeMine = () => {
  const appStore = useAppStore();
  const [userInfo, setUserInfo] = useState(BfAppContext.getInstance().getUserStore().getState().userInfo);

  const commonStore = CommonStore.getInstance();
  const welfareAmount = useStoreState(commonStore, (state) => state.welfareAmount);

  useFocusEffect(
    React.useCallback(() => {
      const userInfo = BfAppContext.getInstance().getUserStore().getState().userInfo;
      setUserInfo(userInfo);
      return;
    }, [])
  );

  return (
    <TwPage
      translucentStatusBar={false}
      footer={
        <View style={styles.footer}>
          <TwButton variant={'solid'} onPress={() => appStore.logout()}>
            退出登录
          </TwButton>
        </View>
      }
    >
      <TwColumn style={styles.container} space={2}>
        {/* Header区域 */}
        <TwColumn style={styles.headerContainer} space={4}>
          {/* 用户信息区域 */}
          <TwRow style={styles.userContainer} space={3}>
            <TwAvatar style={styles.userAvatar}>
              <Text style={styles.avatarDesc}>{userInfo.name?.[0].toUpperCase()}</Text>
            </TwAvatar>
            <View style={styles.userInfo}>
              <Text style={styles.userName}>{userInfo.name}</Text>
              <TwRow style={styles.phoneContainerr} space={1}>
                <MaterialIcons name="mobile-friendly" size={12} color="#A4A4A4" />
                <Text style={styles.userPhone}>{userInfo.mobile}</Text>
              </TwRow>
            </View>
          </TwRow>
          {/* 历史订单\收货地址区域 */}
          <TwRow style={styles.orderContainer}>
            <TwIconButton
              style={styles.iconButton}
              icon={<TwImage source={history} size={30} />}
              label={'历史订单'}
              direction={'vertical'}
              size={'sm'}
              onPress={() => {
                BfAppContext.getInstance().getRouteService().push({
                  path: ROUTE_NAMES.SettingHistoryOrderNew
                });
              }}
            ></TwIconButton>
            <TwIconButton
              style={styles.iconButton}
              icon={<TwImage source={address} size={30} />}
              label={'收货地址'}
              direction={'vertical'}
              size={'sm'}
              onPress={() => {
                BfAppContext.getInstance().getRouteService().push({
                  path: ROUTE_NAMES.SettingAddressChoice
                });
              }}
            ></TwIconButton>
          </TwRow>
        </TwColumn>

        <TwColumn style={styles.content}>
          <TwSection bodyStyle={styles.contentItem}>
            <TwListItem
              title={'可用额度'}
              icon={<TwImage source={money} size={18} />}
              rightContent={<Text style={styles.rightDesc}>{welfareAmount}</Text>}
            />
          </TwSection>
        </TwColumn>

        <TwColumn style={styles.content}>
          <TwSection bodyStyle={styles.contentItem}>
            <TwListItem
              title={'所在部门'}
              showArrow={true}
              icon={<TwImage source={certification} size={18} />}
              rightContent={<Text style={styles.rightDesc}>{userInfo?.employee?.departmentId?.name ?? '未设置'}</Text>}
              pressable={true}
              onPress={() => {
                BfAppContext.getInstance().getRouteService().push({
                  path: ROUTE_NAMES.GiftDepartmentEdit
                });
              }}
            />
          </TwSection>
        </TwColumn>

        <TwColumn style={styles.content}>
          <TwSection bodyStyle={styles.contentItem}>
            <TwListItem
              title={'修改密码'}
              showArrow={true}
              icon={<TwImage source={record} size={18} />}
              pressable={true}
              onPress={() => {
                appStore.onEditPwdClick();
              }}
            />
          </TwSection>
        </TwColumn>
      </TwColumn>
    </TwPage>
  );
};
