import { BfMenu } from '@tw/tw-runtime';
import { RnMenuService } from '@tw/tw-runtime-rn';

export class MenuService extends RnMenuService {
  async fetchMenus(): Promise<BfMenu[]> {
    return [
      {
        id: 'test'
      }
    ];
  }
}
