import { BfMenu, BfMenuStore } from '@tw/tw-runtime';

export class MenuStore extends BfMenuStore {
  async fetchAccessibleMenus(permissionIds: string[]): Promise<BfMenu[]> {
    return [
      {
        id: 'test'
      }
    ];
  }
}
