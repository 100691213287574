import { BfAppContext, BfUserLoginParams, IPushMessageEvent } from '@tw/tw-runtime';
import { RnAppStore, RnRouterService, navigationRef } from '@tw/tw-runtime-rn';

import { CommonActions } from '@react-navigation/native';
import { CommonStore } from './common/common-store';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export class AppStore extends RnAppStore {
  protected isPublicPath(path: string): boolean {
    return false;
  }

  /**
   * 全局推送消息
   * @param message
   * @protected
   */
  protected onGlobalPushMessage(message: IPushMessageEvent) {}

  /**
   * 解绑推送
   * @param silent
   * @protected
   */
  protected async unBindPushNotice(silent: boolean) {
    super.unBindPushNotice(silent);
  }

  protected showPwdSettingPage(): void {
    this.getMessageService().showError('没有实现：设置密码');
  }

  protected async showSettingPwdSkipConfirm(params: BfUserLoginParams): Promise<boolean> {
    return true;
  }

  protected validateWeekPwd(params: BfUserLoginParams): boolean {
    return false;
  }
  async logout(silent?: boolean): Promise<void> {
    await super.logout(silent);
    // await this.goLoginPage();
  }

  protected async afterUserAuthInfoPrepared(): Promise<void> {
    const commonStore = CommonStore.getInstance();
    const currentActivity = await commonStore.getCurrentActivity();
    if (currentActivity) {
      await commonStore.refreshAmountLimit();
      await commonStore.refreshMyCurActivityOrder();
    } else {
      await BfAppContext.getInstance().getMessageService().showInfo('没有正在进行的活动');
    }
    await super.afterUserAuthInfoPrepared();
  }

  protected async afterLogoutSuccess(): Promise<void> {
    this.logger.debug('退出登录成功');
    this.menuStore.onLogout();
    this.goLoginPage();
  }

  protected async initializeServices(): Promise<void> {
    await super.initializeServices();
  }

  async goLoginPage() {
    wx.miniProgram.redirectTo({
      url: '/pages/login/index?logout=true'
    });
  }

  async goEntryPage() {
    // const checkIfNeedInitData = await CommonStore.getInstance().checkIfNeedInitData();
    navigationRef.current?.dispatch((state) => {
      return CommonActions.reset({
        ...state,
        routes: [
          {
            name: ROUTE_NAMES.HomeInitCheck
            // name: ROUTE_NAMES.HomeIndex
          }
        ],
        index: 0
      });
    });
    // this.getRouteService().replace({
    //   path: 'Home'
    // });
  }

  async onEditPwdClick(): Promise<void> {
    BfAppContext.getInstance().getRouteService().push({
      path: ROUTE_NAMES.ChangePassword
    });
  }
}
