import { BfAppContext, BfLoginCodeSendStatus } from '@tw/tw-runtime';
import { Center, Icon, Input, NativeBaseProvider, Pressable, Stack } from 'native-base';
import { ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';
import { TwBfInput, TwBfPhone, TwImageField } from '@tw/tw-platform-rn';
import { TwColumn, TwImage, TwInput, TwPage } from '@tw/tw-components-rn';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';

import { MaterialIcons } from '@expo/vector-icons';
import { ScreenProp } from '@/app/navigation/types';
import { TwButton } from '@/app/components/tw-button/TwButton';
import login from './assets/login.png';
import { styles } from '@/auth/pages/login/styles';

export const AuthLogin = ({ navigation, route }: ScreenProp) => {
  const store = BfAppContext.getInstance().getLoginStore();
  const appStore = useAppStore();

  const userName = useStoreState(store, (state) => state.formState.userName);
  const captcha = useStoreState(store, (state) => state.formState.captcha);
  const pwd = useStoreState(store, (state) => state.formState.pwd);
  const sendingStatus = useStoreState(store, (state) => state.captchaStatus.sendingStatus);
  const countdown = useStoreState(store, (state) => state.captchaStatus.countdown);
  const userLoginLoading = useStoreState(appStore, (state) => state.isDoingLogin);
  const [showPwd, setShowPwd] = useState(false);

  return (
    <TwPage translucentStatusBar={true} style={styles.page}>
      <ImageBackground style={styles.image} source={login}>
        <View style={styles.container} testID={'Container'}>
          {/* 背景图片 */}
          <Text style={styles.welcome}>云尙工惠</Text>
          <TwColumn style={styles.loginContainer} space={4} testID="Login_form">
            <TwColumn testID="Login_userName" space={1}>
              <Text style={styles.title}>工号</Text>
              <TwBfInput
                placeholder="请输入工号"
                value={userName}
                variant={'outline'}
                onChange={(text) => {
                  store.updateLoginForm({
                    userName: text
                  });
                }}
              />
            </TwColumn>
            {/* <TwColumn testID="Login_captcha" space={1}>
            <Text style={styles.title}>验证码</Text>
            <TwInput
              style={styles.input}
              value={captcha}
              size={'md'}
              InputRightElement={
                <TouchableOpacity
                  onPress={() => {
                    store.sendCaptcha();
                  }}
                >
                  // idle 可以发送验证码
                  {sendingStatus === BfLoginCodeSendStatus.sending ? (
                    <Text style={styles.inputAction}>验证码发送中</Text>
                  ) : //验证码发送成功后
                  sendingStatus === BfLoginCodeSendStatus.afterSending ? (
                    <Text style={styles.inputAction}>{`${Math.round(countdown / 1000)}秒后可以发送`}</Text>
                  ) : (
                    <Text style={styles.inputAction}>发送验证码</Text>
                  )}
                </TouchableOpacity>
              }
              placeholder="请输入验证码"
              onChangeText={(text) => {
                store.updateLoginForm({
                  captcha: text
                });
              }}
            ></TwInput>
          </TwColumn> */}
            <TwColumn testID="Login_password" space={1}>
              <Text style={styles.title}>密码</Text>
              <TwInput
                style={styles.input}
                value={pwd}
                type={showPwd ? 'text' : 'password'}
                size={'md'}
                placeholder="请输入密码"
                onChangeText={(text) => {
                  store.updateLoginForm({
                    pwd: text
                  });
                }}
                InputRightElement={
                  <Pressable onPress={() => setShowPwd(!showPwd)}>
                    <Icon
                      as={<MaterialIcons name={showPwd ? 'visibility' : 'visibility-off'} />}
                      size={5}
                      mr="2"
                      color="muted.400"
                    />
                  </Pressable>
                }
              />
            </TwColumn>
            <TwButton
              style={styles.forgetPwd}
              variant={'link'}
              onPress={() => BfAppContext.getInstance().getMessageService().showWarn('请联系【工会】重置密码')}
            >
              忘记密码
            </TwButton>
            <TwButton style={styles.submit} isLoading={userLoginLoading} onPress={() => store.login()}>
              登录
            </TwButton>
          </TwColumn>
        </View>
      </ImageBackground>
    </TwPage>
  );
};
