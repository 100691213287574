import { BfUserInfo, BfUserService } from '@tw/tw-runtime';
import { ResAuthorizationService, ResLoginRequestModel } from '@tw/clvip-api-gen';

export class UserService extends BfUserService {
  private authService: ResAuthorizationService;
  constructor(api) {
    super(api);
    this.authService = new ResAuthorizationService(api);
  }

  async requestLogin(params: ResLoginRequestModel) {
    const res = await this.authService.login(params);
    return res.token!.token!;
  }

  async requestLogout(): Promise<void> {
    return await this.authService.logout();
  }

  async fetchUserInfo(): Promise<BfUserInfo> {
    return await this.authService.userInfoRead();
  }
}
