import { BfAppContext, CoordinatesValue } from '@tw/tw-runtime';

import { ProductTypeEnumValue } from '@tw/clvip-api-gen';

export const getGoodTypeColor = (type: string) => {
  switch (type) {
    case ProductTypeEnumValue.open_package:
      return '#5D7DF0';
    case ProductTypeEnumValue.fixed_package:
      return '#F37046';
    case ProductTypeEnumValue.sale_card:
      return '#72C26B';
  }
};

export const checkAddressParams = (name, phone, address: CoordinatesValue) => {
  if (!name) {
    BfAppContext.getInstance().getMessageService().showError('请填写收货人');
    return false;
  }
  if (!phone) {
    BfAppContext.getInstance().getMessageService().showError('请填写手机号');
    return false;
  }
  if (!address || !address.province?.id || !address.city?.id || !address.county?.id || !address.address) {
    BfAppContext.getInstance().getMessageService().showError('请填写地址');
    return false;
  }

  return true;
};
