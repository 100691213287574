import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import React, { useEffect } from 'react';

import { AuthChangePassword } from '@/auth/pages/change-password/AuthChangePassword';
import { AuthDepartmentEdit } from '@/auth/pages/department/AuthDepartmentEdit';
import { AuthLogin } from '@/auth/pages/login/AuthLogin';
import { AuthVerify } from '@/auth/pages/verify/AuthVerify';
import { BfAppContext } from '@tw/tw-runtime';
import { GiftDepartment } from '@/gift/pages/department/GiftDepartment';
import { GiftGiftConfirmOrder } from '@/gift/pages/gift-confirm-order/GiftGiftConfirmOrder';
import { GiftGiftHomePage } from '@/gift/pages/gift-home-page/GiftGiftHomePage';
import { GiftGiftInfo } from '@/gift/pages/gift-info/GiftGiftInfo';
import { GiftGiftMall } from '@/gift/pages/gift-mall/GiftGiftMall';
import { GiftGiftShopping } from '@/gift/pages/gift-shopping/GiftGiftShopping';
import { HomeIndex } from '@/home/pages/index/HomeIndex';
import { HomeInitCheck } from '@/home/pages/initcheck/HomeInitCheck';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { SettingAddressChoice } from '@/setting/pages/addressChoice/SettingAddressChoice';
import { SettingCreateAddress } from '@/setting/pages/createAddress/SettingCreateAddress';
import { SettingHistoryOrder } from '@/setting/pages/history-order/SettingHistoryOrder';
import { SettingHistoryOrderInfo } from '@/setting/pages/history-order-info/SettingHistoryOrderInfo';
import { SettingHistoryOrderNew } from '@/setting/pages/history-order-new/SettingHistoryOrderNew';
import { SettingPersonal } from '@/setting/pages/personal/SettingPersonal';
import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types';
import { createStackNavigator } from '@react-navigation/stack';
import { navigationRef } from '@tw/tw-runtime-rn';

const Stack = createStackNavigator();
const linking: LinkingOptions<any> = {
  prefixes: ['lock://', 'http://localhost']
};

const SCREEN_OPTIONS: StackNavigationOptions = {
  headerShown: false,
  cardStyle: {
    flex: 1
  },
  headerStyle: {
    height: 40
  },
  title: ''
};

export const AppRouter = () => {
  useEffect(() => {
    BfAppContext.getInstance().getRouteService().trigger('onReady');
  }, []);

  const token = BfAppContext.getInstance().getAuthStorageService().getToken();

  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <Stack.Navigator
        screenOptions={() => {
          return { ...SCREEN_OPTIONS, title: '' };
        }}
        initialRouteName={token ? ROUTE_NAMES.HomeInitCheck : ROUTE_NAMES.AuthLogin}
      >
        <Stack.Screen name={ROUTE_NAMES.AuthLogin} component={AuthLogin} options={{ ...SCREEN_OPTIONS, title: '' }} />
        <Stack.Screen name={ROUTE_NAMES.HomeInitCheck} options={SCREEN_OPTIONS} component={HomeInitCheck} />
        <Stack.Screen
          name={ROUTE_NAMES.AuthVerify}
          // options={{ ...SCREEN_OPTIONS, title:}}
          component={AuthVerify}
        />
        <Stack.Screen name={ROUTE_NAMES.HomeIndex} options={{ ...SCREEN_OPTIONS, title: '' }} component={HomeIndex} />
        <Stack.Screen
          name={ROUTE_NAMES.GiftGiftInfo}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={GiftGiftInfo}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SettingPersonal}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={SettingPersonal}
        />
        {/* <Stack.Screen
          name={ROUTE_NAMES.GiftGiftCustomInfo}
          options={{ ...SCREEN_OPTIONS, title: '礼包详情' }}
          component={GiftGiftCustomInfo}
        /> */}
        <Stack.Screen
          name={ROUTE_NAMES.SettingAddressChoice}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={SettingAddressChoice}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SettingCreateAddress}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={SettingCreateAddress}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SettingHistoryOrder}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={SettingHistoryOrder}
        />
        <Stack.Screen
          name={ROUTE_NAMES.GiftGiftHomePage}
          options={{ ...SCREEN_OPTIONS }}
          component={GiftGiftHomePage}
        />
        <Stack.Screen
          name={ROUTE_NAMES.GiftGiftMall}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={GiftGiftMall}
        />
        <Stack.Screen
          name={ROUTE_NAMES.GiftGiftShopping}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={GiftGiftShopping}
        />
        <Stack.Screen
          name={ROUTE_NAMES.GiftGiftConfirmOrder}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={GiftGiftConfirmOrder}
        />
        <Stack.Screen
          name={ROUTE_NAMES.GiftDepartment}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={GiftDepartment}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SettingHistoryOrderNew}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={SettingHistoryOrderNew}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SettingHistoryOrderInfo}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={SettingHistoryOrderInfo}
        />
        <Stack.Screen
          name={ROUTE_NAMES.ChangePassword}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={AuthChangePassword}
        />
        <Stack.Screen
          name={ROUTE_NAMES.GiftDepartmentEdit}
          options={{ ...SCREEN_OPTIONS, title: '' }}
          component={AuthDepartmentEdit}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
