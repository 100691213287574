import React, { useEffect } from 'react';
import { TwLoadingBlock, TwPage } from '@tw/tw-components-rn';

import { BfAppContext } from '@tw/tw-runtime';
import { CommonStore } from '@/app/core/store/common/common-store';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

/**
 * 检查用户账号是否初始化，如果初始化完成，进入首页，否则进入初始化页面
 * @constructor
 */
export const HomeInitCheck = () => {
  useEffect(() => {
    (async () => {
      const routeService = BfAppContext.getInstance().getRouteService();
      const checkIfNeedInitData = await CommonStore.getInstance().checkIfNeedInitData();
      if (checkIfNeedInitData) {
        routeService.replace({
          path: ROUTE_NAMES.GiftDepartment
        });
      } else {
        routeService.replace({
          path: ROUTE_NAMES.HomeIndex
        });
      }
    })();
  }, []);
  return (
    <TwPage>
      <TwLoadingBlock tip={'认证中'} />
    </TwPage>
  );
};
