import {
  AreaService,
  BfAppContext,
  BfEnumService,
  BoStateService,
  PageSchemaService,
  BoDataService,
  DataviewDataService,
  DocDataService
} from '@tw/tw-runtime';
import { RnFileService, RnMessageService, RnStorageService, RnSystemInfoService } from '@tw/tw-runtime-rn';

import { ApiService } from '@/app/core/services/api-service';
import { AppStore } from '../store/app-store';
import { AuthTokenService } from '@/app/core/services/auth-token-service';
import { BfLocation } from '@tw/tw-platform-rn';
import { LoginStore } from '@/app/core/store/login-store';
import { MenuService } from '@/app/core/services/menu-service';
import { MenuStore } from '../store/menu-store';
import { NoticeStore } from '@/app/core/store/notice-store';
import { Platform } from 'react-native';
import { RouteService } from '@/app/core/services/route-service';
import { UserService } from '@/app/core/services/user-service';
import { UserStore } from '@/app/core/store/user-store';
import { ContextHelper } from '@/app/core/setup/helper';

const messageService = new RnMessageService();

const systemInfoService = new RnSystemInfoService();
const storageService = new RnStorageService();
const authStorageService = new AuthTokenService({
  tokenKey: 'token',
  storageService: storageService
});
const apiService = new ApiService({
  authStorageService: authStorageService,
  messageService: messageService,
  systemInfoService: systemInfoService
});
const enumService = new BfEnumService(apiService, storageService);
const userService = new UserService(apiService);
const routeService = new RouteService();
const boStateService = new BoStateService(apiService);
const boDataService = new BoDataService(apiService);
const dataviewDataService = new DataviewDataService(apiService);
const docDataService = new DocDataService(apiService);
// const socketPushService = new PushService(apiService, authStorageService, {
//   appId: '3will',
//   appKey: '3will-key',
//   useTLS: false,
//   wsHost: '120.27.210.39',
//   wsPort: '6001',
//   logToConsole: true
// });

const menuService = new MenuService(apiService, 'mobile');
const fileService = new RnFileService(apiService, {
  uploadUrl: '/v2/rest/services/boService/infra/file/upload',
  fileKey: 'file',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

const pageSchemaService = new PageSchemaService(apiService);
const areaService = new AreaService(apiService);
BfAppContext.getInstance().setApiService(apiService);
BfAppContext.getInstance().setMessageService(messageService);
BfAppContext.getInstance().setStorageService(storageService);
BfAppContext.getInstance().setRouteService(routeService);
BfAppContext.getInstance().setBoStateService(boStateService);
BfAppContext.getInstance().setFileService(fileService);
BfAppContext.getInstance().setUserService(userService);
BfAppContext.getInstance().setEnumService(enumService);
BfAppContext.getInstance().setPageSchemaService(pageSchemaService);
BfAppContext.getInstance().setAuthStorageService(authStorageService);
BfAppContext.getInstance().setAreaService(areaService);
BfAppContext.getInstance().setLoginPath('Login');
BfAppContext.getInstance().setDataviewDataService(dataviewDataService);
BfAppContext.getInstance().setDocDataService(docDataService);
BfAppContext.getInstance().setBoDataService(boDataService);

// const pushStore = new BfPushStore({
//   services: [socketPushService]
// });
const menuStore = new MenuStore({
  menuService,
  displayMode: 'topSide'
});
const userStore = new UserStore({
  authStorageService,
  userService
});
const noticeStore = new NoticeStore({
  apiService: apiService
});
// const menuStore= new MenuStore({
//   menuService
// });
const appStore = new AppStore({
  userStore,
  messageService,
  menuStore,
  authStorageService,
  enumService,
  storageService,
  // pushStore,
  noticeStore
});
// BfAppContext.getInstance().setPushStore(pushStore);

BfAppContext.getInstance().setAppStore(appStore);
BfAppContext.getInstance().setUserStore(userStore);
BfAppContext.getInstance().setMenuStore(menuStore);
BfAppContext.getInstance().setDebug(true);
BfAppContext.getInstance().setLoginPath('Home');
BfAppContext.getInstance().setLoginStore(
  new LoginStore({
    userService: userService
  })
);
BfLocation.getInstance().initMapKey(
  Platform.select({
    android: 'e5767a6d9306f4b54d82b7feef2ea384',
    ios: 'e5767a6d9306f4b54d82b7feef2ea384'
  })
);
BfLocation.getInstance().initWebApi('0beabf754486ea4f19671d7a954da7ba');
BfLocation.getInstance().initWebJsKey('981d27a1dc0d5fbfd5c293a4d3edce7d');
const token = ContextHelper.getToken();
if (token) {
  authStorageService.setToken(token);
}
