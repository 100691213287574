export const ROUTE_NAMES = {
  // 闪屏
  Splash: 'Splash',
  // 登录
  AuthLogin: 'Login',
  // 登录验证码
  AuthVerify: 'AuthVerify',
  // 修改密码
  ChangePassword: 'ChangePassword',
  // 进入主页前检查是否登录
  HomeInitCheck: 'HomeInitCheck',
  // 主页
  HomeIndex: 'HomeIndex',
  HomeMine: 'HomeMine',
  GiftGiftList: 'GiftGiftList',
  // 礼包详情
  GiftGiftInfo: 'GiftGiftInfo',
  // // 自选礼包详情
  // GiftGiftCustomInfo: 'GiftGiftCustomInfo',
  // 基本信息设定页面
  SettingPersonal: 'SettingPersonal',
  // 地址选择
  SettingAddressChoice: 'SettingAddressChoice',
  // 新建地址
  SettingCreateAddress: 'SettingCreateAddress',
  // 历史订单
  SettingHistoryOrder: 'SettingHistoryOrder',
  //历史订单-新版本
  SettingHistoryOrderNew: 'SettingHistoryOrderNew',
  //历史订单详情页
  SettingHistoryOrderInfo: 'SettingHistoryOrderInfo',
  //新的主页 doing
  GiftGiftHomePage: 'GiftGiftHomePage',
  //商城页面
  GiftGiftMall: 'GiftGiftMall',
  // 购物车->确认订单页面
  GiftGiftConfirmOrder: 'GiftGiftConfirmOrder',
  // 清单 list
  GiftGiftShopping: 'GiftGiftShopping',
  //登录后选择所在部门页面
  GiftDepartment: 'GiftDepartment',
  // 部门修改页面
  GiftDepartmentEdit: 'GiftDepartmentEdit'
};
