import { ICustomTheme, TwTheme } from '@tw/tw-runtime-rn';
import { extendTheme } from 'native-base';
import { mapColorValues } from '@tw/tw-components-rn';
// 修改 TwTheme
TwTheme.mergeTheme({
  primaryColors: {
    base: '#8FBD69'
  },
  colors: {
    olive: {
      1: '#f4f8f0',
      2: '#e4efdb',
      3: '#cfe3bf',
      4: '#b8d5a1',
      5: '#a3c984',
      6: '#8fbd69',
      7: '#7aa159',
      8: '#66864b',
      9: '#526c3c',
      10: '#40552f'
    }
  },
  shadow: {
    card: {
      shadowColor: TwTheme.values.colors.black,
      shadowOffset: {
        width: 0,
        height: 0
      },
      shadowRadius: 10,
      shadowOpacity: 0.06,
      elevation: 1
    },
    cardInfo: {
      shadowColor: TwTheme.values.colors.black,
      shadowOffset: {
        width: 0,
        height: 0
      },
      shadowRadius: 2,
      shadowOpacity: 0.1,
      elevation: 2
    }
  }
} as ICustomTheme);
console.log('TwTheme.values.primaryColors.base', TwTheme.values.primaryColors.base);
// 覆盖 native base 主题色
export const theme = extendTheme({
  colors: {
    primary: mapColorValues(TwTheme.values.colors.olive)
  }
});
