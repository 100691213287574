function getSearchParams() {
  const href = location.href;
  const start = href.lastIndexOf('?');
  if (start == -1) {
    return {};
  }
  const queryStr = href.substring(start + 1, href.length);
  if (!queryStr) {
    return {};
  }
  const queryMaps = queryStr.split('&');
  const queryParams: any = {};
  if (queryMaps) {
    queryMaps.forEach((item) => {
      const params = item.split('=');
      queryParams[params[0]] = params[1];
    });
  }
  return queryParams;
}

function getToken() {
  const params = getSearchParams();
  return params.token;
}

export const ContextHelper = {
  getSearchParams,
  getToken
};
